/* eslint-disable */
import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    <strong>
                        Prepara’t amb el millor curs per a la Policia Local de Sant Boi de Llobregat
                    </strong>{" "}
                    i assegura’t l’èxit amb materials actualitzats.
                </p>

                <p>
                    <strong>No deixis passar aquesta oportunitat!</strong>
                </p>

                <p>
                    <strong>Ara comptem amb classes en directe setmanals.</strong>
                </p>

                <p>
                    No busquis més: <strong>el millor material està a iOpos</strong>. Aquest curs és
                    imprescindible per posicionar-te entre els millors en l'oposició del 2025.
                </p>

                <p>
                    Es tracta d’un <strong>curs viu</strong>, amb{" "}
                    <strong>actualitzacions constants</strong> i en evolució, presentat en un campus
                    virtual amb <strong>contingut de qualitat</strong>, fàcil d'utilitzar i altament
                    efectiu.
                </p>

                <p>
                    <strong>Dins del curs trobaràs:</strong>
                </p>

                <ul>
                    <li>Cultura general: bàsica i avançada</li>
                    <li>
                        Notícies d'actualitat setmanals, tests per posar-te a prova i videoresums
                        mensuals
                    </li>
                    <li>Tot el Municipi exigit a les bases, amb tots els detalls</li>
                    <li>Dades rellevants de Sant Boi de Llobregat: festes, monuments, etc.</li>
                    <li>Tot el temari exigit a la convocatòria</li>
                    <li>2 exàmens oficials per posar-te a prova</li>
                    <li>Vídeos que cobreixen tot el temari</li>
                    <li>VideoQuizz de Cultura General</li>
                    <li>I, a més, simulacres mensuals</li>
                </ul>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="No només estudies, avances: materials actualitzats, vídeo-tests i simulacres mensuals per arribar preparat al 100%"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
